export default {
  namespaced: true, // namespaceの設定：Vuexストアのモジュール分割が有効になる
  state: {
    keycloak: null,
    userName: null,
  },
  getters: {
    keycloakToken: state => state.keycloak.token,
    keycloakUserName: state => state.userName,
  },
  mutations: {
    SET_KEYCLOAK(state, value) {
      state.keycloak = value
    },
    SET_USERNAME(state, data) {
      console.log('[mutations] set userName')
      state.userName = data
    },
  },
  actions: {
    logout({ state }) {
      if (window.location.origin === undefined) {
        window.location.origin = `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`
      }
      const options = {
        redirectUri: window.location.origin,
      }
      state.keycloak.logout(options)
    },
  },
}
