import { mdiCog, mdiFileDocumentPlusOutline } from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Pages' },

  { title: 'データ作成', icon: mdiFileDocumentPlusOutline, to: 'home' },
  {
    title: '銀行設定',
    icon: mdiCog,
    to: 'bank-setting',
  },
]
