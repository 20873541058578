import { mdiCog, mdiFileDocumentPlusOutline } from '@mdi/js'

export default [
  {
    title: 'データ作成',
    icon: mdiFileDocumentPlusOutline,
    to: 'home',
  },
  {
    title: '銀行設定',
    icon: mdiCog,
    to: 'bank-setting',
  },
]
