/* eslint-disable import/no-cycle */
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import auth from './auth'
import setting_bank from './setting_bank'
import zengin_data from './zengin_data'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    axios: null,
  },
  mutations: {
    setAxios(state, axios) {
      console.log('[mutations] set axios')
      state.axios = axios
    },
  },
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    setting_bank,
    zengin_data,
    auth,
  },
})
