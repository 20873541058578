import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import * as Keycloak from 'keycloak-js'
import Vue from 'vue'
import VueLogger from 'vuejs-logger'

import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueLogger)

Vue.config.productionTip = false

// axios
const domain = 'https://api.zengin-pipeline.com'

// const domain = "http://127.0.0.1:8000";
axios.defaults.baseURL = domain
store.commit('setAxios', axios)

const decodeJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
}

function setUserName() {
  const token = store.getters['auth/keycloakToken']

  const decode_token = decodeJwt(token)
  const { family_name } = decode_token
  const { given_name } = decode_token
  store.commit('auth/SET_USERNAME', family_name + given_name)
}

// keycloak constructor options
const constructorOptions = {
  url: 'https://auth.zengin-pipeline.com/auth',
  realm: 'ZenginPipeline',
  clientId: 'web-frontend',
}

// keycloak init options
const initOptions = {
  onLoad: 'login-required',
}

function execCallback(callback) {
  const keycloak = Keycloak(constructorOptions)
  callback(keycloak)
}

const myCallback = keycloak => {
  keycloak
    .init(initOptions)
    .success(async auth => {
      if (!auth) {
        window.location.reload()
      } else {
        Vue.$log.info('Authenticated')
      }

      // Keycloakオブジェクトをストアに保存
      store.commit('auth/SET_KEYCLOAK', keycloak)

      setUserName()

      const app = new Vue({
        render: h => h(App),
        vuetify,
        store,
        router,
      })
      app.$mount('#app')

      setInterval(() => {
        keycloak
          .updateToken(70)
          .success(refreshed => {
            if (refreshed) {
              Vue.$log.debug('Token refreshed')
            } else {
              Vue.$log.warn(
                `Token not refreshed, valid for ${Math.round(
                  keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000,
                )} seconds`,
              )
            }
          })
          .error(() => {
            Vue.$log.error('Failed to refresh token')
          })
      }, 60000)
    })
    .error(() => {
      Vue.$log.error('Authenticated Failed')
    })
}

execCallback(myCallback)

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App),
// }).$mount('#app')
