export default {
  namespaced: true,
  state: {
    data_from_excel: {},
    furikae_date: '',
    sousin_date: '',
    total_num: 0,
    total_money_amount: 0,
    header: [],
    data: [],
  },
  getters: {
    get_data_from_excel(state) {
      return state.data_from_excel
    },
  },
  mutations: {
    setFurikaeDate(state, date) {
      state.furikae_date = date
    },
    setSousinDate(state, date) {
      state.sousin_date = date
    },
    setDataFromExcel(state, data_from_excel) {
      state.data_from_excel = data_from_excel
    },
    setTotalNum(state, total_num) {
      state.total_num = total_num
    },
    setTotalMoneyAmount(state, total_money_amount) {
      state.total_money_amount = total_money_amount
    },
    setHeader(state, headers) {
      state.header = headers
    },
    setData(state, datas) {
      state.data = datas
    },
  },
  actions: {
    test() {
      console.log('this is a dispatch')
    },
  },
}
