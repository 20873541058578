/* eslint-disable import/no-cycle */
import store from '@/store'

export default {
  namespaced: true, // namespaceの設定：Vuexストアのモジュール分割が有効になる
  state: {
    setting_bank: [],
  },
  getters: {
    getSettingBank(state) {
      return state.setting_bank
    },
  },
  mutations: {
    setSettingBank(state, data) {
      console.log('[mutations] set setting_bank')
      state.setting_bank = data
    },
  },
  actions: {
    test() {
      console.log('Token', store.getters['auth/keycloakToken'])
    },
    readSettingBank({ commit }) {
      console.log('[actions] readSettingBank')

      return new Promise((resolve, reject) => {
        store.state.axios
          .get('/setting/bank', {
            headers: {
              Authorization: `Bearer ${store.getters['auth/keycloakToken']}`,
            },
          })
          .then(
            response => {
              resolve(response.data)

              commit('setSettingBank', response.data.result)
              console.log(response.data.result.length)
            },
            error => {
              reject(error)
            },
          )
      })
    },
    createSettingBank({ commit, state }, { data }) {
      console.log('[actions] createSettingBank')

      return new Promise((resolve, reject) => {
        store.state.axios
          .post('/setting/bank', data, {
            headers: {
              Authorization: `Bearer ${store.getters['auth/keycloakToken']}`,
            },
          })
          .then(
            response => {
              resolve(response.data)
              console.log(response.data)
            },
            error => {
              reject(error)
            },
          )
      })
    },
    updateSettingBank({ commit, state }, { params, data }) {
      console.log('[actions] updateSettingBank')

      return new Promise((resolve, reject) => {
        store.state.axios
          .patch(`${'/setting/bank?setting_bank_id='}${params.setting_bank_id}`, data, {
            headers: {
              Authorization: `Bearer ${store.getters['auth/keycloakToken']}`,
            },
          })
          .then(
            response => {
              resolve(response.data)

              // commit('setSchoolProfile', response.data)
              console.log(response.data)
            },
            error => {
              reject(error)
            },
          )
      })
    },

    deleteSettingBank({ commit, state }, { params }) {
      console.log('[actions] deleteSettingBank')

      return new Promise((resolve, reject) => {
        store.state.axios
          .delete(`${'/setting/bank?setting_bank_id='}${params.setting_bank_id}`, {
            headers: {
              Authorization: `Bearer ${store.getters['auth/keycloakToken']}`,
            },
          })
          .then(
            response => {
              resolve(response.data)

              // commit('setSchoolProfile', response.data)
              console.log(response.data)
            },
            error => {
              reject(error)
            },
          )
      })
    },
  },
}
